import React from "react";
import Layout from "../components/layout";
import Section from "../components/section";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <Section>
      <h1>ページが見つかりませんでした。</h1>
      <p>申し訳ございません。お探しのページは見つかりませんでした。</p>
      <p>
        <Link to="/">こちらからトップページ</Link>に戻れます。
      </p>
    </Section>
  </Layout>
);

export default () => <NotFoundPage />;
